import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SelectList from "@components/selectList";
import SearchBar from "@components/searchBar";
import TableCSI from "@components/table";
import DateRangePicker from "rsuite/DateRangePicker";
import TableDropdown from "@components/customDropdown";
import BadgeApproval from "@components/badgeApproval";

import "rsuite/DateRangePicker/styles/index.css";

const TablePromo = () => {
  const [column, setColumn] = useState([]);

  useEffect(() => {
    setColumn([
      {
        Header: () => null,
        id: "promoNew",
        columns: [
          {
            Header: "Nama",
            accessor: "nama",
            sortAble: true,
            width: 200,
          },
          {
            Header: "Tipe Promo",
            accessor: "tipe_promo",
            sortAble: true,
            width: 100,
          },
          {
            Header: "Periode",
            accessor: "periode",
            sortAble: true,
            width: 300,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeApproval status={row.original.status} />,
          },
          {
            accessor: "action",
            Cell: ({ row }) => (
              <TableDropdown
                dropdownContent={[
                  {
                    label: "Detail",
                    type: "link",
                    link: `/dashboard/flashsale/detail/${row.original.id}`,
                  },
                  {
                    label: "Edit",
                    type: "link",
                    link: `/dashboard/flashsale/detail/${row.original.id}`,
                  },
                  {
                    label: "Delete",
                    type: "delete",
                  },
                ]}
                row={row.original}
                onDeleteAction={(id) => console.log(id)}
              />
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);
  }, []);

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between">
        <h3>Buat Promo</h3>
        <Link to="/dashboard/promotion/add">
          <button className="px-3 py-2 rounded-lg btn-primary">
            New Promotion
          </button>
        </Link>
      </div>
      <div className="d-flex flex-row justify-content-between my-2">
        <div className="d-flex flex-row w-100">
          <div className="mr-2 w-25">
            <SelectList
              placeholder="Tipe Promo"
              onChange={() => {}}
              values={[]}
            />
          </div>
          <DateRangePicker
            size="md"
            placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
            block
          />
        </div>
        <div>
          <SearchBar noentry onSearch={() => {}} />
        </div>
      </div>
      <div>
        <TableCSI columns={column} data={[]} />
      </div>
    </Fragment>
  );
};

export default TablePromo;
