import { Fragment } from "react";
import { Modal } from "reactstrap";

import filterCustomer from "@assets/images/filter__customer.svg";
import filterUser from "@assets/images/filter__user.svg";
import filterSales from "@assets/images/filter__sales.svg";
import filterWlarea from "@assets/images/filter__wlarea.svg";

const PopUpFilter = ({ isOpen, onToggle, onAddFilter }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={onToggle} centered size="lg">
        <div className="d-flex flex-row justify-content-between p-3">
          <h5 className="my-2 font-weight-bolder">Pilih Filter</h5>
          <button
            type="button"
            onClick={onToggle}
            className="border-0 bg-white p-auto"
          >
            <i className="fa-solid fa-x"></i>
          </button>
        </div>
        <div className="px-3 py-2 container-fluid">
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="d-flex flex-row bg-white my-3 p-2 border rounded-lg w-100"
				onClick={() => onAddFilter('customer')}
              >
                <img
                  src={filterCustomer}
                  alt="filter by customer"
                  className="bg-light mx-1 my-auto p-2 rounded-circle"
                />
                <div className="d-flex flex-column mx-1 text-left">
                  <span className="font-weight-bold">Customer</span>
                  <span>Filter berdasarkan customer</span>
                </div>
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="d-flex flex-row bg-white my-3 p-2 border rounded-lg w-100"
				onClick={() => onAddFilter('sales')}
              >
                <img
                  src={filterSales}
                  alt="filter by sales"
                  className="bg-light mx-1 my-auto p-2 rounded-circle"
                />
                <div className="d-flex flex-column mx-1 text-left">
                  <span className="font-weight-bold">Sales</span>
                  <span>Filter berdasarkan sales</span>
                </div>
              </button>
            </div>
            <div className="w-100"></div>
            <div className="col">
              <button
                type="button"
                className="d-flex flex-row bg-white my-3 p-2 border rounded-lg w-100"
				onClick={() => onAddFilter('wlarea')}
              >
                <img
                  src={filterWlarea}
                  alt="filter by wil area"
                  className="bg-light mx-1 my-auto p-2 rounded-circle"
                />
                <div className="d-flex flex-column mx-1 text-left">
                  <span className="font-weight-bold">WL Area</span>
                  <span>Filter berdasarkan WL Area</span>
                </div>
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="d-flex flex-row bg-white my-3 p-2 border rounded-lg w-100"
				onClick={() => onAddFilter('user')}
              >
                <img
                  src={filterUser}
                  alt="filter by jenis customer"
                  className="bg-light mx-1 my-auto p-2 rounded-circle"
                />
                <div className="d-flex flex-column mx-1 text-left">
                  <span className="font-weight-bold">Jenis Customer</span>
                  <span>Filter berdasarkan jenis customer</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default PopUpFilter;
