import { Fragment, useState } from "react";

import { InputSwitch } from "@components/formComponent";
import SwitchAndOr from "../component/SwitchAndOr";
import PopUpKondisi from "../component/PopUpKondisi";
import FilterByCustomer from "../component/filterByCustomer";
import FilterBySales from "../component/filterBySales";
import FilterByWLarea from "../component/filterByWLarea";
import FilterByUser from "../component/filterByUser";
import FilterByKondisi from "../component/filterByKondisi";

const EditKondisiCustomer = () => {
  const [openModal, setModal] = useState(false);
  const [isCustomer, setCustomer] = useState(false);
  const [isSales, setSales] = useState(false);
  const [isWLarea, setWLarea] = useState(false);
  const [isUser, setUser] = useState(false);
  const [isEditKondisi, setEditKondisi] = useState(false);

  function handleEditFilter(val) {
    switch (val) {
      case "customer":
        setCustomer(true);
        break;
      case "sales":
        setSales(true);
        break;
      case "wlarea":
        setWLarea(true);
        break;
      case "user":
        setUser(true);
        break;
      default:
        break;
    }
  }

  function handleEditKondisi() {
    setModal(false);
    setEditKondisi(true);
  }

  return (
    <Fragment>
      <div className="my-3 p-3 border rounded-lg">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex my-1">
            <div className="my-auto">
              <InputSwitch
                value
                onChange={(val) => console.log(val.target.checked)}
              />
            </div>
            <span className="mx-2 font-weight-bold h5">Customer</span>
          </div>
          <div className="d-flex">
            <SwitchAndOr value={""} onChange={() => {}} />
            <button
              type="button"
              className="ml-2 btn btn-primary"
              onClick={() => setModal(!openModal)}
            >
              {"+"} Tambah Kondisi
            </button>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center my-2 p-3">
          {isCustomer && (
            <FilterByCustomer onToggle={() => setCustomer(!isCustomer)} />
          )}
          {isSales && <FilterBySales onToggle={() => setSales(!isSales)} />}
          {isWLarea && <FilterByWLarea onToggle={() => setWLarea(!isWLarea)} />}
          {isUser && <FilterByUser onToggle={() => setUser(!isUser)} />}

          {isEditKondisi && (
            <FilterByKondisi onDeleteFilter={() => setEditKondisi(false)} />
          )}

          {!isCustomer && !isSales && !isWLarea && !isUser && !isEditKondisi ? (
            <div className="bg-light p-3 rounded-lg text-center text-dark">
              <span>Silahkan untuk menambahkan filter</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <PopUpKondisi
        kondisi="customer"
        isOpen={openModal}
        onToggle={() => setModal(!openModal)}
        onEditFilter={handleEditFilter}
        onEditKondisi={handleEditKondisi}
      />
    </Fragment>
  );
};

export default EditKondisiCustomer;
