import { Fragment, useState } from "react";

import { InputSwitch } from "@components/formComponent";
import SwitchAndOr from "../component/SwitchAndOr";
import PopUpKondisi from "../component/PopUpKondisi";
import FilterByKondisi from "../component/filterByKondisi";
import FilterByManufacture from "../component/filterByManufacture";
import FilterByKategori from "../component/filterByKategori";
import FilterBySKU from "../component/filterBySKU";

const AddKondisiProduct = () => {
  const [openModal, setModal] = useState(false);
  const [isManufaktur, setManufaktur] = useState(false);
  const [isKategori, setKategori] = useState(false);
  const [isSKU, setSKU] = useState(false);
  const [isAddKondisi, setAddKondisi] = useState(false);

  function handleAddFilter(val) {
    switch (val) {
      case "manufaktur":
        setManufaktur(true);
        break;
      case "kategori":
        setKategori(true);
        break;
      case "sku":
        setSKU(true);
        break;
      default:
        break;
    }
  }

  function handleAddKondisi() {
    setModal(false);
    setAddKondisi(true);
  }

  return (
    <Fragment>
      <div className="my-3 p-3 border rounded-lg">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex my-1">
            <div className="my-auto">
              <InputSwitch
                value
                onChange={(val) => console.log(val.target.checked)}
              />
            </div>
            <span className="mx-2 font-weight-bold h5">Product</span>
          </div>
          <div className="d-flex">
            <SwitchAndOr value={""} onChange={() => {}} />
            <button
              className="btn btn-primary"
              onClick={() => setModal(!openModal)}
            >
              {"+"} Tambah Kondisi
            </button>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center my-2 p-3">
          {isManufaktur && (
            <FilterByManufacture
              onToggle={() => setManufaktur(!isManufaktur)}
            />
          )}
          {isKategori && (
            <FilterByKategori onToggle={() => setKategori(!isKategori)} />
          )}
          {isSKU && (
            <FilterBySKU onToggle={() => setSKU(!isSKU)} />
          )}

          {isAddKondisi && (
            <FilterByKondisi onDeleteFilter={() => setAddKondisi(false)} />
          )}

          {!isManufaktur && !isKategori && !isSKU && !isAddKondisi ? (
            <div className="bg-light p-3 rounded-lg text-center text-dark">
              <span>Silahkan untuk menambahkan filter</span>
            </div>
          ) : (
            ""
          )}
        </div>

        <PopUpKondisi
          kondisi="product"
          isOpen={openModal}
          onToggle={() => setModal(!openModal)}
          onAddFilter={handleAddFilter}
          onAddKondisi={handleAddKondisi}
        />
      </div>
    </Fragment>
  );
};

export default AddKondisiProduct;
