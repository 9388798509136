import { Fragment } from "react";
import SelectList from "@components/selectList";

const FilterByUser = ({ onToggle }) => {
  return (
    <Fragment>
      <div className="bg-light my-2 border rounded-lg text-dark">
        <div className="d-flex flex-row justify-content-between my-2 p-2">
          <span className="font-weight-bold">Jenis Customer</span>
          <button className="border-0 bg-none" onClick={onToggle}>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{ color: "red" }}
            ></i>
          </button>
        </div>
        <div className="d-flex flex-row justify-content-between my-2 px-1 py-2">
          <SelectList
            placeholder="Choose"
            onChange={() => {}}
            values={[
              { name: "All", val: "all" },
              { name: "Choose", val: "choose" },
              { name: "except", val: "except" },
            ]}
          />
          <SelectList
            placeholder="Pilih Jenis Customer"
            onChange={() => {}}
            values={[]}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FilterByUser;
