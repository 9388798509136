import { Fragment } from "react";
import {
  FormGroup,
  Label,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import PropTypes from "prop-types";

const InputNumber = ({ label, value, onChange, addOnText, isRequired }) => {
  return (
    <Fragment>
      <FormGroup row>
        <Label className="font-weight-bold" for={`form${label}`} sm={2}>
          {label}
          {isRequired ? (
            <span className="mx-1" style={{ color: "red" }}>
              *
            </span>
          ) : null}
        </Label>
        <Col sm={10}>
          <InputGroup>
            <Input
              id={`formNumber${label}`}
              placeholder={`Enter ${label}`}
              value={value}
              onChange={onChange}
              min={0}
              step={1}
              type="number"
            />
            {addOnText && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{addOnText}</InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        </Col>
      </FormGroup>
    </Fragment>
  );
};

InputNumber.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  addOnText: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default InputNumber;
