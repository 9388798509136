import { Fragment, useState, useEffect } from "react";
import { FormGroup, Label, Col, Input } from "reactstrap";
import PropTypes from "prop-types";

const InputDateRange = ({
  className,
  label,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  isRequired,
}) => {
  const [stat_date, setStart_date] = useState(startDate || "");
  const [end_date, setEnd_date] = useState(endDate || "");

  useEffect(() => {
    setStart_date(startDate || "");
    setEnd_date(endDate || "");
  }, [startDate, endDate]);

  const onChangeDate = (e, type) => {
    switch (type) {
      case "startDate":
        setStart_date(e.target.value);
        onChangeStartDate(e.target.value);
        break;
      case "endDate":
        setEnd_date(e.target.value);
        onChangeEndDate(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div className={className}>
      <FormGroup row>
        {label ? (
          <Label className="font-weight-bold" for={`form${label}`} sm={2}>
            {label}
            {isRequired ? (
              <span className="mx-1" style={{ color: "red" }}>
                *
              </span>
            ) : null}
          </Label>
        ) : null}
        <Col className="d-flex justify-content-between" sm={10}>
          <Input
            type="date"
            value={stat_date}
            onChange={(e) => onChangeDate(e, "startDate")}
          />
          <span className="mx-2 my-auto">{"-"}</span>
          <Input
            type="date"
            value={end_date}
            onChange={(e) => onChangeDate(e, "endDate")}
          />
        </Col>
      </FormGroup>
    </div>
  );
};

InputDateRange.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  addOnText: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default InputDateRange;
