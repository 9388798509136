import { Fragment, useState } from "react";
import PropTypes from "prop-types";

const SwitchAndOr = ({ label, value, onChange, isRequired }) => {
  const [active, setActive] = useState(0);

  function handleOnClick(active, value){
	setActive(active)
	onChange(value)
  }

  return (
    <Fragment>
      <div className="d-flex bg-light p-1 rounded-lg">
        <button
          className={`btn rounded-lg py-1 px-3 mx-1 ${active === 0 ? "btn-primary" : ""}`}
		  onClick={() => handleOnClick(0, 'and')}
        >
          AND
        </button>
        <button
          className={`btn rounded-lg py-1 px-3 mx-1 ${active === 1 ? "btn-primary" : ""}`}
		  onClick={() => handleOnClick(1, 'or')}
        >
          OR
        </button>
      </div>
    </Fragment>
  );
};

SwitchAndOr.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default SwitchAndOr;
