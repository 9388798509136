import { Fragment, useState } from "react";

import Breadcrumbs from "@layout/breadcrumb";
import BadgeApproval from "@components/badgeApproval";
import AlertApproval from "@components/alertApproval";
import { ButtonAction } from "@components/styledComponents";

const DetailPromotion = () => {
  const [modalData, setModalData] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  function handleAlert() {
    setModalData({
      isOpen: false,
      isNote: false,
      title: "",
      subTitle: "",
    });
  }

  return (
    <Fragment>
      <Breadcrumbs
        parent="Promotion"
        title="Detail Promosi"
        urlParent={"/dashboard/promotion"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="bg-white shadow-sm col-sm-12 py-2 rounded-lg h-100 col-xl-4">
            <div className="d-flex flex-row">
              <h5 className="my-auto">Sample Title</h5>
              <div className="mx-2 my-auto">
                <BadgeApproval status={1} />
              </div>
            </div>
            <div className="d-flex flex-row border-top justify-content-between border-bottom py-3 my-3">
              <ButtonAction className="mx-1 font-weight-bold w-50 p-10">
                Edit
              </ButtonAction>
              <ButtonAction className="mx-1 font-weight-bold w-50 p-10">
                Delete
              </ButtonAction>
              <ButtonAction className="mx-1 font-weight-bold w-50 p-10">
                Inactive
              </ButtonAction>
            </div>
            <div className="d-flex flex-column my-2">
              <span className="text-secondary">Kode Promo</span>
              <span className="">SAMPLE</span>
            </div>
            <div className="d-flex flex-column my-2">
              <span className="text-secondary">Tipe Promosi</span>
              <span className="">Other</span>
            </div>
            <div className="d-flex flex-column my-2">
              <span className="text-secondary">Periode</span>
              <span className="">01/01/2000 - 01/01/2002</span>
            </div>
          </div>

          <div className="col-sm-12 ml-xl-auto col-xl-8">
            <div className="bg-white shadow-sm mb-3 px-3 p-t-15 rounded-lg w-100">
              <h5 className="mb-3">Customer</h5>
              <div className="d-flex flex-row justify-content-between my-2">
                <div>
                  <p className="font-weight-bold mb-0">Target Customer</p>
                  <p>Semua Customer</p>
                </div>
                <div>
                  <p className="font-weight-bold mb-0">Discount</p>
                  <p>20%</p>
                </div>
                <div>
                  <p className="font-weight-bold mb-0">Potongan Maksimal</p>
                  <p>Rp. 150.000</p>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm mb-3 px-3 p-t-15 rounded-lg w-100">
              <h5 className="mb-3">Manufacturer</h5>
              <div className="d-flex flex-row justify-content-between my-2">
                <div>
                  <p className="font-weight-bold mb-0">Target Manufacturer</p>
                  <p>Semua Manufacturer</p>
                </div>
                <div>
                  <p className="font-weight-bold mb-0">Discount</p>
                  <p>20%</p>
                </div>
                <div>
                  <p className="font-weight-bold mb-0">Potongan Maksimal</p>
                  <p>Rp. 150.000</p>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm mb-3 px-3 p-t-15 rounded-lg w-100">
              <h5 className="mb-3">Category</h5>
              <div className="d-flex flex-row my-2">
                <div className="w-50">
                  <p className="font-weight-bold mb-0">Target Category</p>
                  <p>Semua Category</p>
                </div>
                <div className="w-50">
                  <p className="font-weight-bold mb-0">Promosi</p>
                  <p>Free Installation</p>
                </div>
              </div>
            </div>
            <div className="bg-white shadow-sm mb-3 px-3 p-t-15 rounded-lg w-100">
              <h5 className="mb-3">Type</h5>
              <div className="d-flex flex-row justify-content-between my-2">
                <div>
                  <p className="font-weight-bold mb-0">Target Type</p>
                  <p>Semua Type</p>
                </div>
                <div>
                  <p className="font-weight-bold mb-0">Discount</p>
                  <p>20%</p>
                </div>
                <div>
                  <p className="font-weight-bold mb-0">Potongan Maksimal</p>
                  <p>Rp. 150.000</p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between bg-white shadow-sm mb-3 px-3 p-t-15 rounded-lg w-100">
              <h5 className="mb-3">Minimum Quantity</h5>
              <h5 className="mb-3">0</h5>
            </div>
            <div className="d-flex flex-row justify-content-between bg-white shadow-sm mb-3 px-3 p-t-15 rounded-lg w-100">
              <h5 className="mb-3">Quota Promosi</h5>
              <h5 className="mb-3">0</h5>
            </div>
            <div className="bg-white shadow-sm mb-3 px-3 p-t-15 rounded-lg w-100">
              <h5 className="mb-3">WL Area</h5>
              <div className="d-flex flex-row justify-content-between my-2">
                <div>
                  <p className="font-weight-bold mb-0">Target WL Area</p>
                  <p>Semua WL Area</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AlertApproval
          isOpenAlert={modalData.isOpen}
          isNote={modalData.isNote || false}
          modalTitle={modalData.title}
          modalSubTitle={modalData.subTitle}
          handleToggle={handleAlert}
        />
      </div>
    </Fragment>
  );
};

export default DetailPromotion;
