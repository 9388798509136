import { Fragment, useState } from "react";
import SelectList from "@components/selectList";
import { InputRadio, InputNumber } from "@components/formComponent";

const FilterBySKU = ({ onToggle }) => {
  return (
    <Fragment>
      <div className="bg-light my-2 px-2 border rounded-lg text-dark">
        <div className="d-flex flex-row justify-content-between my-2 p-2">
          <span className="font-weight-bold">SKU</span>
          <button className="border-0 bg-none" onClick={onToggle}>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{ color: "red" }}
            ></i>
          </button>
        </div>
        <div className="d-flex flex-row justify-content-between my-2 px-1 py-2">
          <SelectList
            placeholder="Choose"
            onChange={() => {}}
            values={[
              { name: "All", val: "all" },
              { name: "Choose", val: "choose" },
              { name: "except", val: "except" },
            ]}
          />
          <SelectList placeholder="Pilih SKU" onChange={() => {}} values={[]} />
        </div>
        <div className="d-flex flex-row mx-1">
          <InputRadio
            name="radioSKU"
            label="Tiering"
            checked={false}
            onChange={() => {}}
          />
          <InputRadio
            name="radioSKU"
            label="Kelipatan"
            checked={false}
            onChange={() => {}}
          />
        </div>
        <div className="bg-white my-2 p-2">
          <div className="d-flex flex-row justify-content-between my-2">
            <h6>Ricecooker</h6>
            <div className="d-flex flex-row mx-1">
              <InputRadio
                name="radioAmount"
                label="Qty"
                checked={false}
                onChange={() => {}}
              />
              <InputRadio
                name="radioAmount"
                label="Value"
                checked={false}
                onChange={() => {}}
              />
            </div>
          </div>
          <Fragment>
            <div className="my-2 row">
              <div className="col-2">Tier 1</div>
              <div className="col-10">
                <InputNumber value={0} onChange={() => {}} />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-2">Tier 2</div>
              <div className="d-flex col-10">
                <div className="w-100">
                  <InputNumber value={0} onChange={() => {}} />
                </div>
                <button className="border-0 bg-white">
                  <i
                    className="mx-2 my-auto fa fa-times"
                    aria-hidden="true"
                    style={{ color: "red" }}
                  ></i>
                </button>
              </div>
            </div>
          </Fragment>
          <button className="my-2 w-100 btn btn-outline-primary">
            {"+"} Tambah Tier
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default FilterBySKU;
