import { Fragment, useState } from "react";
import SwitchAndOr from "./SwitchAndOr";
import PopUpKondisi from "./PopUpKondisi";

const FilterByKondisi = ({ onDeleteFilter }) => {
  const [openModal, setModal] = useState(false);

  return (
    <Fragment>
      <div className="bg-light p-3 border rounded-lg text-center text-dark">
        <div className="d-flex flex-row justify-content-between mb-2">
          <h5>Kondisi 1</h5>
          <div className="d-flex">
            <SwitchAndOr value={""} onChange={() => {}} />
            <button
              type="button"
              className="ml-2 btn btn-primary"
              onClick={() => setModal(!openModal)}
            >
              {"+"} Tambah Kondisi
            </button>
            <button className="border-0 bg-none" onClick={onDeleteFilter}>
              <i
                className="fa fa-trash"
                aria-hidden="true"
                style={{ color: "red" }}
              ></i>
            </button>
          </div>
        </div>
        <span className="my-3">Silahkan untuk menambahkan filter</span>
        <PopUpKondisi
          isOpen={openModal}
          onToggle={() => setModal(!openModal)}
          onAddFilter={() => {}}
        />
      </div>
    </Fragment>
  );
};

export default FilterByKondisi;
