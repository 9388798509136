import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "reactstrap";
import Breadcrumbs from "@layout/breadcrumb";
import TableLayout from "@layout/tableLayout";
import TableCSI from "@components/table";
import PopupAdd from "./popupAdd";
import PopEdit from "./popEdit";
import { ModalDialog } from "@components/modal/ModalDialog";
import ModalContent from "@components/modal/ModalContent";

import {
  getListMasterAproval,
  deleteMasterApproval,
} from "@action/masterAprovalAction";

const MasterApproval = () => {
  const dispatch = useDispatch();
  const { listMasterApproval } = useSelector(
    (state) => state.MasterApprovalReducer
  );

  const [isFetchedData, setIsFetchedData] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [data, setData] = useState([]);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [indexTable, setIndexTable] = useState(0);
  const [isEdit, setEdit] = useState(false);
  const [rowData, setRowData] = useState({});
  const [modalState, setModalState] = useState(null);

  useEffect(() => {
    fetchTable();
  }, []);

  useEffect(() => {
    if (listMasterApproval.data && !listMasterApproval.loading) {
      const data = listMasterApproval.data.data;
      const list = listMasterApproval.data.data.data;
      const mappeddata = list.map((item) => ({
        id: item.id,
        nama: item.user.username,
        role: item.role_string,
        roleID: item.role,
        user: item.user.owner_name,
        userID: item.user_id,
      }));

      setMaxPageIndex(data.last_page);
      setTotalEntry(data.total);
      setTotalEntryPerPage(data.to);
      setData(mappeddata);
      setIsFetchedData(true);
    }
  }, [listMasterApproval]);

  const fetchTable = () => {
    setTableColumn([
      {
        Header: () => null,
        id: "configAdmin1",
        columns: [
          {
            Header: "No",
            accessor: "id",
            width: 30,
            sortAble: true,
          },
          {
            Header: "Nama",
            accessor: "nama",
            minWidth: 200,
            sortAble: true,
          },
          {
            Header: "Role",
            accessor: "role",
            minWidth: 250,
            sortAble: true,
          },
          {
            Header: "User",
            accessor: "user",
            minWidth: 250,
            sortAble: true,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex">
                <button
                  className="border rounded-lg bg-white px-3 py-2 mr-2"
                  onClick={() => handleEdit(row.original)}
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(row?.original)}
                  className="border rounded-lg bg-white px-3 py-2 mr-2"
                  style={{ color: "red" }}
                >
                  Delete
                </button>
              </div>
            ),
            disableSortBy: true,
            width: 40,
          },
        ],
      },
    ]);

    dispatch(getListMasterAproval(1, "", 10));
  };

  function handleEdit(data) {
    setRowData(data);
    setEdit(true);
  }

  function handleDelete(data) {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "Status",
      modalTitle: "Delete",
      modalData: data,
      closeIcon: true,
    }));
  }

  function onDelete() {
    dispatch(deleteMasterApproval(modalState.modalData.id)).then((res) => {
      if (res.status !== 200) return;

	  fetchTable()
	  setModalState(null)
    });
  }

  function onUpdateAdd() {
    setOpenPopup(!isOpenPopup);
    fetchTable();
  }

  function onUpdateEdit() {
    setEdit(!isEdit);
    fetchTable();
  }

  const tableAction = (
    <Button color="primary" outline onClick={() => setOpenPopup(true)}>
      Add New Approver
    </Button>
  );

  return (
    <Fragment>
      <Breadcrumbs
        parent="Dashboard"
        title="Master Approval"
        urlParent={"/dashboard/analytics"}
      />
      <TableLayout
        headerAction={tableAction}
        handleSearch={() => {}}
        totalEntry={totalEntry}
        totalEntryPerPage={totalEntryPerPage}
        pageCount={maxPageIndex}
        handleChangePage={() => {}}
        handleChangeRow={(val) => console.log(val)}
      >
        <TableCSI columns={tableColumn} data={data} key={data} />
      </TableLayout>
      <PopupAdd
        isOpen={isOpenPopup}
        togglePopup={onUpdateAdd}
        index={indexTable}
      />
      <PopEdit
        isOpen={isEdit}
        togglePopup={onUpdateEdit}
        index={indexTable}
        data={rowData}
      />
      <ModalDialog
        open={modalState?.openModal ?? false}
        type={modalState?.modalType ?? ""}
        footer={false}
        title={modalState?.modalTitle ?? ""}
        titleFontSize={18}
        backdropClose={true}
        onClose={() => {
          setModalState(null);
        }}
        maxWidth={384}
      >
        <ModalContent
          onClose={() => {
            setModalState(null);
          }}
          onSubmit={onDelete}
          data={modalState?.modalData ?? null}
          descClose={"Batal"}
          descSubmit={"Submit"}
          description={`Delete Role?`}
        />
      </ModalDialog>
    </Fragment>
  );
};

export default MasterApproval;
