import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SelectList from "@components/selectList";
import SearchBar from "@components/searchBar";
import TableCSI from "@components/table";
import DateRangePicker from "rsuite/DateRangePicker";
import BadgeApproval from "@components/badgeApproval";

import "rsuite/DateRangePicker/styles/index.css";

const TableApproval = () => {
  const [column, setColumn] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setColumn([
      {
        Header: () => null,
        id: "promoApproval",
        columns: [
          {
            Header: "Nama",
            accessor: "nama",
            sortAble: true,
            width: 200,
          },
          {
            Header: "Tipe Promo",
            accessor: "tipe_promo",
            sortAble: true,
            width: 100,
          },
          {
            Header: "Periode",
            accessor: "periode",
            sortAble: true,
            width: 300,
          },
          {
            Header: "Pengaju",
            accessor: "pengaju",
            sortAble: true,
            width: 300,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeApproval status={row.original.status} />,
          },
          {
            accessor: "action",
            Cell: ({ row }) => (
              <Link
                to={`/dashboard/promotion/detail-approval/${row.original.id}`}
              >
                <button className="bg-white px-3 py-1 border rounded-lg font-weight-bold text-dark">
                  Detail
                </button>
              </Link>
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);

    setData([
      {
        id: 1,
        nama: "Sample",
        tipe_promo: "sample",
        periode: "sample",
        pengaju: "sample",
        status: 1,
      },
    ]);
  }, []);

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between">
        <h3>Approval</h3>
        <Link to="/dashboard/promotion/add">
          <button className="px-3 py-2 rounded-lg btn-primary">
            New Promotion
          </button>
        </Link>
      </div>
      <div className="d-flex flex-row justify-content-between my-2">
        <div className="d-flex flex-row w-100">
          <div className="d-flex flex-row mr-2 w-25">
            <SelectList
              placeholder="Tipe Promo"
              onChange={() => {}}
              values={[]}
            />
            <SelectList placeholder="Pengaju" onChange={() => {}} values={[]} />
          </div>
          <DateRangePicker
            size="md"
            placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
            block
          />
        </div>
        <div>
          <SearchBar noentry onSearch={() => {}} />
        </div>
      </div>
      <div>
        <TableCSI columns={column} data={data} />
      </div>
    </Fragment>
  );
};

export default TableApproval;
