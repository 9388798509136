import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

//component
import { Container, Row, Col } from "reactstrap";
import {
  InputText,
  InputDateRange,
  InputArea,
  InputImage,
  InputRadio,
} from "@components/formComponent";
import Breadcrumb from "@layout/breadcrumb";
import AddKondisiCustomer from "./addKondisiCustomer";
import AddKondisiProduct from "./addKondisiProduct";
import SelectList from "@components/selectList";

const NewPromotionAdd = () => {
  const [namaPromo, setNamaPromo] = useState("");
  const [kodePromo, setKodePromo] = useState("");
  const [kuotaPromo, setKuotaPromo] = useState("");
  const [tipePromo, setTipePromo] = useState("other");
  const [periode, setPeriode] = useState("");
  const [image, setImage] = useState(null);
  const [deskripsi, setDeskripsi] = useState("");
  const [openModal, setModal] = useState(false);
  const [benefit, setBenefit] = useState("");

  return (
    <Fragment>
      <Breadcrumb
        parent="Promotion"
        title="Add New Promotion"
        urlParent={"/dashboard/promo"}
      />
      <Container fluid>
        <Row>
          <Col className="my-sm-2" sm={12} lg={8}>
            <div className="bg-white shadow-sm mb-3 p-3 border rounded-lg">
              <h5>Informasi Promo</h5>
              <InputText
                label="Nama Promo"
                value={namaPromo}
                onChange={(e) => setNamaPromo(e.target.value)}
              />
              <InputText
                label="Kode Promo"
                value={kodePromo}
                onChange={(e) => setKodePromo(e.target.value)}
              />
              <InputText
                label="Kuota Promo"
                value={kuotaPromo}
                onChange={(e) => setKuotaPromo(e.target.value)}
              />
              <InputImage
                label="Banner Promo"
                value={[]}
                onChange={(images) => {}}
              />
              <InputArea
                label="Deskripsi"
                value={deskripsi}
                onChange={(e) => setDeskripsi(e.target.value)}
              />
              <Row>
                <Col
                  className="d-flex flex-lg-column flex-xl-row justify-content-between"
                  sm={2}
                >
                  <InputRadio
                    name="radioPromo"
                    label="Other"
                    checked={tipePromo === "other" ? true : false}
                    onChange={() => setTipePromo("other")}
                  />
                  <InputRadio
                    name="radioPromo"
                    label="Flashsale"
                    checked={tipePromo === "flashsale" ? true : false}
                    onChange={() => setTipePromo("flashsale")}
                  />
                </Col>
                <Col sm={10}>
                  <InputDateRange
                    className="w-100"
                    startDate={""}
                    endDate={""}
                    onChangeStartDate={(val) => {}}
                    onChangeEndDate={(val) => {}}
                  />
                </Col>
              </Row>
            </div>

            <div className="d-flex flex-column bg-white shadow-sm my-3 p-3 border rounded-lg">
              <h4>Kondisi</h4>
              <div className="p-2">
                <AddKondisiCustomer onOpenModal={() => setModal(!openModal)} />
                <AddKondisiProduct onOpenModal={() => setModal(!openModal)} />
              </div>
              <button className="ml-auto btn btn-primary">Save Kondisi</button>
            </div>

            <div className="d-flex flex-column bg-white shadow-sm my-3 p-3 border rounded-lg">
              <h5>Benefit</h5>
              <div className="row">
                <div className="col-3">
                  <h6 className="my-auto">Benefit</h6>
                </div>
                <div className="col-9">
                  <SelectList
                    placeholder="Pilih Jenis Benefit"
                    onChange={(e) => setBenefit(e.target.value)}
                    values={dataBenefit}
                    value={benefit}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="mx-xl-auto my-sm-2" sm={12} lg={3}>
            <div className="bg-white shadow-sm mx-auto p-3 border rounded-lg">
              <h5>Preview Informasi</h5>
              <hr />
              <div>
                <span className="font-weight-bold h6">Informasi Promo</span>
                <div className="d-flex flex-row my-2">
                  <div className="mr-auto">
                    <p className="text-secondary">Nama Promo</p>
                    <p>{namaPromo}</p>
                  </div>
                  <div className="mr-auto">
                    <p className="text-secondary">Kode Promo</p>
                    <p>{kodePromo}</p>
                  </div>
                </div>
                <div className="d-flex flex-row my-2">
                  <div className="mr-auto">
                    <p className="text-secondary">Tipe Promo</p>
                    <p>{tipePromo}</p>
                  </div>
                  <div className="mr-auto">
                    <p className="text-secondary">Periode</p>
                    <p>{periode}</p>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <span className="font-weight-bold h6">Customer</span>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div>
              <hr />
              <div>
                <span className="font-weight-bold h6">Product</span>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              </div>
              <hr />
              <div>
                <span className="font-weight-bold h6">Ketentuan</span>
                <div className="bg-light my-2 p-2 rounded-lg text-dark">
                  <span className="font-weight-bold">Tier 1</span>
                  <div className="d-flex flex-row justify-content-between">
                    <span>Cosmos</span>
                    <span>x10</span>
                  </div>
                </div>
              </div>
              <div>
                <span className="font-weight-bold h6">Benefit</span>
                <div className="bg-light my-2 p-2 rounded-lg text-dark">
                  <span className="font-weight-bold">Tier 1</span>
                  <div className="d-flex flex-row justify-content-between">
                    <span>Voucher 1.0</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end my-3">
              <button className="bg-white mr-2 px-3 py-2 border-light rounded">
                Batal
              </button>
              <button className="mr-2 px-3 py-2 rounded btn-primary">
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const dataBenefit = [
  { name: "Potongan Harga" },
  { name: "Free Gift" },
  { name: "Voucher" },
  { name: "CN" },
];

export default NewPromotionAdd;
